import {graphql, useStaticQuery} from 'gatsby'
import React, {ReactNode} from 'react'
import {Helmet} from 'react-helmet'
import '../../styles/blog.css'
import {Footer, Header} from '../common'
import styles from './layout.module.css'

type Props = {
  children?: ReactNode
  bodyClass?: string
  isBlogIndex?: boolean
  title?: string
}

const logoColors = {
  primary: `#fefce1`,
  secondary: `#2c72b6`,
  shadow: `transparent`,
}

export const Layout = ({children, bodyClass, isBlogIndex, title}: Props) => {
  const data = useStaticQuery(graphql`
    query GhostSettings {
      allGhostSettings {
        edges {
          node {
            ...GhostSettingsFields
          }
        }
      }
    }
  `)
  const site = data.allGhostSettings.edges[0].node
  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>
      <div className="viewport">
        <Header
          logoColors={logoColors}
          title={isBlogIndex ? `Blog` : title ? title : `Untitled`}
          headerClass={styles.headerSection}
        />
        <main className="site-main">
          {/* All the main content gets inserted here, index.ts, post.tsx */}
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}
